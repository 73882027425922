$(function () {
    "use strict";

    $(".social-list").on("click", function () {
        if ($(this).attr("id") === "youtube") {
            window.open("#", "_blank");
        }
        if ($(this).attr("id") === "instagram") {
            window.open(
                "https://www.instagram.com/accounts/login/?next=/yuanchuangsheji/",
                "_blank"
            );
        }
        if ($(this).attr("id") === "facebook") {
            window.open(
                "https://www.facebook.com/circularity.design",
                "_blank"
            );
        }
    });

    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };
    /**
     * Mobile nav toggle
     */
    $(".mobile-nav-toggle").on("click", function (e) {
        select("#navbar").classList.toggle("navbar-mobile");
        this.classList.toggle("bi-list");
        this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    $(".navbar .dropdown > a").on("click", function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
            e.preventDefault();
            this.nextElementSibling.classList.toggle("dropdown-active");
            // if ($(".dropdown_toggle").hasClass("bi-caret-down-fill")) {
            //     $(this).removeClass("bi-caret-down-fill");
            //     $(this).addClass("bi-caret-up-fill");
            // } else {
            //     $(this).addClass("bi-caret-down-fill");
            //     $(this).removeClass("bi-caret-up-fill");
            // }
        }
    });

    var current_fs, next_fs, previous_fs; //fieldsets
    var opacity;

    $(".input-button").on("click", function () {
        var value = $(this).val();
        var input = $(this).parent().find("input:hidden:first");
        input.val(value);
    });

    $(".next").on("click", function () {
        var v1 = document.getElementById("name");
        var v2 = document.getElementById("phone");
        var response = grecaptcha.getResponse();
        current_fs = $(this).parent();
        next_fs = $(this).parent().next();

        if ($(this).attr("id") == "start") {
            var flag = true;

            if (v1.value == "") {
                v1.style.borderColor = "red";
                flag = false;
            } else {
                v1.style.borderColor = "green";
                flag = true;
            }

            if (v2.value == "") {
                v2.style.borderColor = "red";
                flag = false;
            } else {
                v2.style.borderColor = "green";
                flag = true;
            }

            if (response.length == 0) {
                document.getElementById("captcha").innerHTML = "請勾選";
                flag = false;
            }

            if (flag == false) {
                return false;
            }
        }

        //show the next fieldset
        next_fs.show();
        // if (next_fs.attr("id") === "budgetField") {
        //     var roomType = $("#roomsType").val();
        //     console.log(roomType);
        //     if (roomType == "一房一廳") {
        //         $("#budget1").val("70萬");
        //         $("#budget2").val("90萬");
        //         $("#budget3").val("140萬");
        //     }

        //     if (roomType == "兩房一廳") {
        //         $("#budget1").val("90萬");
        //         $("#budget2").val("140萬");
        //         $("#budget3").val("190萬");
        //     }

        //     if (roomType == "三房兩廳") {
        //         $("#budget1").val("140萬");
        //         $("#budget2").val("190萬");
        //         $("#budget3").val("240萬");
        //     }

        //     if (roomType == "四房兩廳") {
        //         $("#budget1").val("190萬");
        //         $("#budget2").val("240萬");
        //         $("#budget3").val("290萬");
        //     }

        //     if (roomType == "獨棟透天") {
        //         $("#budget1").val("340萬~430萬");
        //         $("#budget2").val("440萬~530萬");
        //         $("#budget3").val("540萬~650萬");
        //     }
        // }

        //hide the current fieldset with style
        current_fs.animate(
            { opacity: 0 },
            {
                step: function (now) {
                    // for making fielset appear animation
                    opacity = 1 - now;

                    current_fs.css({
                        display: "none",
                        position: "relative",
                    });
                    next_fs.css({ opacity: opacity });
                },
                duration: 600,
            }
        );
    });

    $(".previous").on("click", function () {
        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate(
            { opacity: 0 },
            {
                step: function (now) {
                    // for making fielset appear animation
                    opacity = 1 - now;

                    current_fs.css({
                        display: "none",
                        position: "relative",
                    });
                    previous_fs.css({ opacity: opacity });
                },
                duration: 600,
            }
        );
    });

    $(".radio-group .radio").click(function () {
        $(this).parent().find(".radio").removeClass("selected");
        $(this).addClass("selected");
    });

    $(".submit").on("click", function () {
        return false;
    });

    $("#msform").on("submit", function (e) {
        e.preventDefault();
        let name = $("#name").val();
        let phone = $("#phone").val();
        let spaceType = $("#spaceType").val();
        let location = $("#location").val();
        let houseType = $("#houseType").val();
        let size = $("#size").val();
        let roomsType = $("#roomsType").val();
        let closingDate = $("#closingDate").val();
        let movingDate = $("#movingDate").val();
        let designType = $("#designType").val();
        // let budget = $("#budget").val();
        let schedule = $("#schedule").val();

        $.ajax({
            url: "/submit-form",
            type: "POST",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                name: name,
                phone: phone,
                spaceType: spaceType,
                location: location,
                houseType: houseType,
                size: size,
                roomsType: roomsType,
                closingDate: closingDate,
                movingDate: movingDate,
                designType: designType,
                // budget: budget,
                schedule: schedule,
            },
            success: function (response) {
                $("#successMsg").show();
                console.log(response);
            },
            error: function (response) {
                $("#nameErrorMsg").text(response.responseJSON.errors.name);
                $("#emailErrorMsg").text(response.responseJSON.errors.email);
                $("#mobileErrorMsg").text(response.responseJSON.errors.mobile);
                $("#messageErrorMsg").text(
                    response.responseJSON.errors.message
                );
            },
        });
    });

    $("#contactForm").on("submit", function (e) {
        e.preventDefault();
        let name = $("#inputName").val();
        let email = $("#inputEmail").val();
        let phone = $("#inputPhone").val();
        let message = $("#inputMessage").val();
        var response = grecaptcha.getResponse();
        if (response.length == 0) {
            document.getElementById("captcha").innerHTML = "請勾選";
            return false;
        }
        $.ajax({
            url: "/send-email",
            type: "POST",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                name: name,
                phone: phone,
                email: email,
                message: message,
            },
            success: function (response) {
                $("#contactForm :input").attr("disabled", "disabled");
                $("#contactForm").fadeTo("slow", 1, function () {
                    $(this).find(":input").attr("disabled", "disabled");
                    $(this).find("label").css("cursor", "default");
                    // $("#success").fadeIn();
                    // $(".modal").modal("hide");
                    // $("#success").modal("show");
                    $("#contactForm").trigger("reset");
                });
            },
            error: function (response) {
                console.log(response);
            },
        });
    });

    $("#mosaic").Mosaic({
        maxRowHeight: 650,
        refitOnResize: true,
        refitOnResizeDelay: true,
        defaultAspectRatio: 1,
        maxRowHeightPolicy: "crop",
        responsiveWidthThreshold: 500,
        innerGap: 10,
    });

    $('.overlay').on('click', function() {
        var target = $(this).closest('div').find('a');
        var url = target.attr('href');
        window.location.href = url;

    })
});
